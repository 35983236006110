import React from 'react'
import {
  Container,
  Section,
  Seo,
} from '@components'
import { LandingPageLayout } from '../components/site/layout/landing-page-layout'
import { OfferForm } from '../components/site/offer'
import { ShieldCheckIcon } from '../components/core/icons/shield-check'

const offer = 'mobile_app_protection'

const Headline = ({ title }) => {
  return (
    <Section>
      <Container className="container-lg">
        <h1 className="mt-12 mb-4 text-3xl text-center">{title}</h1>
        <h3 className="mt-4 mb-2 text-2xl text-center text-secondary-800">
          Key Requirements That Mobile App Security Must Address
        </h3>
      </Container>
      <Container className="mt-12 container-md">
        <p>
          As you evaluate mobile app protection solutions there are a number of
          key requirements to consider. Obfuscation of the mobile app code
          itself is just one of them. This table lists some other requirements
          which are just as critical.
        </p>
        <p>
          To help you assess mobile app shielding vendors, we have also prepared
          a short guide which explains ten things you must consider when
          evaluating potential solutions.
        </p>
      </Container>
    </Section>
  )
}

const Pitch = () => {
  const features = [
    {
      name: 'Runtime Secrets Protection',
      zimperium: '',
      guardsquare: '',
      verimatrix: '',
      appdome: '',
      promon: '',
      approov: 'x',
    },
    {
      name: 'Dynamic Pinning MitM Protection',
      zimperium: '',
      guardsquare: '',
      verimatrix: '',
      appdome: '',
      promon: '',
      approov: 'x',
    },
    {
      name: 'Remote Mobile App Attestation',
      zimperium: '',
      guardsquare: '',
      verimatrix: '',
      appdome: '',
      promon: '',
      approov: 'x',
    },
    {
      name: 'Mobile API Lockdown',
      zimperium: '',
      guardsquare: '',
      verimatrix: '',
      appdome: '',
      promon: '',
      approov: 'x',
    },
    {
      name: 'Transparent App Usage Based Pricing',
      zimperium: '',
      guardsquare: '',
      verimatrix: 'x',
      appdome: '',
      promon: '',
      approov: 'x',
    },
    {
      name: 'Code Obfuscation',
      zimperium: 'x',
      guardsquare: 'x',
      verimatrix: 'x',
      appdome: 'x',
      promon: 'x',
      approov: '',
    },
    {
      name: 'App Anti-Tampering',
      zimperium: 'x',
      guardsquare: 'x',
      verimatrix: 'x',
      appdome: 'x',
      promon: 'x',
      approov: 'x',
    },
    {
      name: 'Root/Jailbreak Detection',
      zimperium: 'x',
      guardsquare: 'x',
      verimatrix: 'x',
      appdome: 'x',
      promon: 'x',
      approov: 'x',
    },
    {
      name: 'Debug/Emulator Detection',
      zimperium: 'x',
      guardsquare: 'x',
      verimatrix: 'x',
      appdome: 'x',
      promon: 'x',
      approov: 'x',
    },
    {
      name: 'Advanced Environment Detections',
      zimperium: 'x',
      guardsquare: 'x',
      verimatrix: 'x',
      appdome: 'x',
      promon: 'x',
      approov: 'x',
    },
    {
      name: 'Real-Time Policy Updates',
      zimperium: 'x',
      guardsquare: '',
      verimatrix: 'x',
      appdome: '',
      promon: '',
      approov: 'x',
    },
    {
      name: 'Protect APIs From Abuse',
      zimperium: '',
      guardsquare: '',
      verimatrix: 'x',
      appdome: '',
      promon: 'x',
      approov: 'x',
    },
    {
      name: 'Live Threat Monitoring',
      zimperium: 'x',
      guardsquare: 'x',
      verimatrix: 'x',
      appdome: 'x',
      promon: '',
      approov: 'x',
    },
    {
      name: 'Integrated Platform Attestation',
      zimperium: '',
      guardsquare: '',
      verimatrix: '',
      appdome: 'x',
      promon: '',
      approov: 'x',
    },
    {
      name: 'Remote Management of 3rd Party API Keys',
      zimperium: '',
      guardsquare: '',
      verimatrix: '',
      appdome: '',
      promon: 'x',
      approov: 'x',
    },
  ]

  return (
    <div>
      <div className="flex flex-col mt-">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {/* <h2 className="title">Mobile App Protection - Key Requirements</h2> */}
              <h2 className="table-title">Mobile App Protection - Key Requirements</h2>

              <table className="min-w-full divide-y divide-gray-300 feature-table">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {' '}
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      
                       Approov
                      
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Appdome
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Guardsquare
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Promon
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                     Verimatrix
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Zimperium
                    </th>
                  </tr>
                </thead>
                
                <tbody className="bg-white divide-y divide-gray-200">
                  {features.map((feature) => (
                    <tr key={feature.name}>
                      <td className="py-0 pl-4 pr-3 text-md text-secondary-800 whitespace-wrap sm:pl-6">
                        {feature.name}
                      </td>
                      <td className="px-2 pt-2 pb-0 text-sm font-medium text-center pt- text-primary-600 whitespace-nowrap">
                        {feature.approov ? (
                          <ShieldCheckIcon className="h-5 mx-auto" />
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="px-2 pt-2 pb-0 text-sm text-center pt- text-primary-600 whitespace-nowrap">
                        {feature.appdome ? (
                          <ShieldCheckIcon className="h-5 mx-auto" />
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="px-2 pt-2 pb-0 text-sm text-center pt- text-primary-600 whitespace-nowrap">
                        {feature.guardsquare ? (
                          <ShieldCheckIcon className="h-5 mx-auto" />
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="px-2 pt-2 pb-0 text-sm text-center pt- text-primary-600 whitespace-nowrap">
                        {feature.promon ? (
                          <ShieldCheckIcon className="h-5 mx-auto" />
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="px-2 pt-2 pb-0 text-sm text-center pt- text-primary-600 whitespace-nowrap">
                        {feature.verimatrix ? (
                          <ShieldCheckIcon className="h-5 mx-auto" />
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="px-2 pt-2 pb-0 text-sm text-center pt- text-primary-600 whitespace-nowrap">
                        {feature.zimperium ? (
                          <ShieldCheckIcon className="h-5 mx-auto" />
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Action = () => {
  return (
    <div>
      <h3 className="mt-4 mb-2 text-2xl text-center text-secondary-800 lg:mt-0">
        Get the Guide!
      </h3>
      <OfferForm offer={offer} />
    </div>
  )
}

export const MobileAppProtectionLP = ({ location, name, title }) => {
  return (
    <LandingPageLayout pathname={location.pathname}>

      <Headline title={title} />

      <Section>
        <Container className="mb-8 container-lg">
          <div className="flex flex-col justify-center lg:flex-row lg:content-center lg:items-center">
            <div className="flex items-center justify-center lg:w-9/12">
              <Pitch />
            </div>

            <div className="lg:pl-8 lg:pr-0 lg:w-3/12">
              <Action />
            </div>
          </div>
        </Container>
      </Section>
    </LandingPageLayout>
  )
}
